import { NextRouter } from 'next/router'
import { create } from 'zustand'
import { Project } from '@/app/core/interface'
import data from '@/public/data/data.json'
interface Store {
  lenis: any
  dom: unknown
  isHover: boolean
  isProduction: boolean
  currentProject: number
  router: NextRouter | null
  projects: Project[]
  isReady: boolean
  isShowIndicator: boolean
  setLenis: (lenis: any) => void
  setHover: (isHover: boolean) => void
  setCurrentProject: (currentProject: number) => void
  setProjects: (projects: Project[]) => void
  fetchProjects: () => void
  setPageReady: (isReady: boolean) => void
  setShowIndicator: (isReady: boolean) => void
}

export const useStore = create(
  (set): Store => ({
    dom: null,
    router: null,
    isHover: false,
    lenis: undefined,
    currentProject: 0,
    projects: [],
    isProduction: process.env.NODE_ENV === 'production' ? true : false,
    isShowIndicator: true,
    isReady: true,
    setHover: (isHover: boolean) => set(() => ({ isHover: isHover })),
    setLenis: (lenis) => set({ lenis }),
    setCurrentProject: (currentProject: number) => set({ currentProject }),
    setProjects: (projects: Project[]) => set({ projects }, true), // true for replace old state
    fetchProjects: async () => {
      // const res = await fetch('../data/data.json')
      set({ projects: data })
    },
    setPageReady: (isReady: boolean) => set({ isReady }),
    setShowIndicator: (isShowIndicator: boolean) => set({ isShowIndicator }),
  })
)
