import dynamic from 'next/dynamic'

const Header = dynamic(() => import('./header/index'), { ssr: false })
const Preloader = dynamic(() => import('./preloader/index'), { ssr: false })
const Blob = dynamic(() => import('./blob/index'))
const Social = dynamic(() => import('./social/index'))
const Greeting = dynamic(() => import('./greeting/index'), { ssr: false })
const Logo = dynamic(() => import('./logo/index'))
const Indicator = dynamic(() => import('./indicator/index'), { ssr: false })
const BigTitle = dynamic(() => import('./webgl/title'))
const AnimatedTitle = dynamic(() => import('./animated-title/index'), {
  ssr: false,
})
const ImageWrapper = dynamic(() => import('./image-wrapper/index'), {
  ssr: false,
})
const Container = dynamic(() => import('./container/index'))
const Marquee = dynamic(() => import('./marquee/index'))
const ScrollAnimation = dynamic(() => import('./scroll-animation/index'), {
  ssr: false,
})
const Navigation = dynamic(() => import('./navigation/index'), {
  ssr: false,
})
const ProjectDetailTitle = dynamic(() => import('./motions/project-detail'), {
  ssr: false,
})
const ProjectName = dynamic(() => import('./motions/project-name'), {
  ssr: false,
})
const Description = dynamic(() => import('./description/index'), {
  ssr: false,
})
const PageLoading = dynamic(() => import('./shared/CurveLoading'), {
  ssr: false,
})
const WorkDetail = dynamic(() => import('./work-detail/work-detail'))

const TextReveal = dynamic(() => import('./motions/text-reveal'), {
  ssr: false,
})
const TextDispere = dynamic(() => import('./text-dispere/index'), {
  ssr: false,
})
// WebGL
const Lights = dynamic(() => import('./webgl/lights'))
const Rig = dynamic(() => import('./webgl/rig'), { ssr: false })
const Wave = dynamic(() => import('./webgl/wave'), { ssr: false })
const Text = dynamic(() => import('./webgl/text'), { ssr: false })
const Particles = dynamic(() => import('./webgl/particles'), { ssr: false })
const Face = dynamic(() => import('./webgl/face'), { ssr: false })
const WaveSlider = dynamic(() => import('./wave-slider/index'), { ssr: false })

const Avatar = dynamic(() => import('./avatar/index'))
const CopyRight = dynamic(() => import('./shared/CopyRight'))
const LoadingIcon = dynamic(() => import('./shared/LoadingIcon'))
export {
  Header,
  Preloader,
  Blob,
  Social,
  Greeting,
  Logo,
  Indicator,
  Lights,
  Rig,
  Wave,
  Text,
  Particles,
  Face,
  BigTitle,
  AnimatedTitle,
  WaveSlider,
  ImageWrapper,
  Container,
  Marquee,
  ScrollAnimation,
  Navigation,
  ProjectDetailTitle,
  Description,
  PageLoading,
  ProjectName,
  WorkDetail,
  TextReveal,
  CopyRight,
  Avatar,
  LoadingIcon,
  TextDispere,
}
