'use client'
import { useStore } from '@/lib/store'
import { BigTitle, Face, Particles, Rig, LoadingIcon } from '@components/index'
import dynamic from 'next/dynamic'
import { Suspense, useLayoutEffect } from 'react'
import { Gradient } from 'lib/Gradient'

const View = dynamic(
  () => import('@/components/canvas/View').then((mod) => mod.View),
  {
    loading: () => <LoadingIcon />,
  }
)

export default function Page() {
  const isPageReady = useStore((state) => state.isReady)
  useLayoutEffect(() => {
    const gradient = new Gradient()
    // @ts-ignore
    gradient.initGradient('#gradient-canvas')
  }, [])
  return (
    <>
      <canvas id="gradient-canvas" data-transition-in />
      <div
        className={`relative mx-auto flex w-screen touch-none select-none flex-col flex-wrap items-center h-dynamic md:flex-row ${
          isPageReady ? 'block' : 'hidden'
        }`}
      >
        <View className="z-[1] w-screen h-dynamic">
          <Suspense fallback={null}>
            <Rig isCamera={true} speed={0.0025}>
              <Face />
              <Particles
                depth={1000}
                count={1000}
                scale={500}
                size={100}
                speed={0.015}
                color={'#fdfdfd'}
                width={500}
                height={100}
              />
            </Rig>
          </Suspense>
        </View>

        {/* <BigTitle /> */}
        <div
          className={`noise absolute left-1/2 top-0 w-full rounded-b-[50px] opacity-20`}
        ></div>
      </div>
    </>
  )
}
